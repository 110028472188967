<template>
	<div class="user-page">
		<div class="search-module mb20">
			<a-input-search v-show="curInd == 0" v-model="searchInfo" placeholder="请输入姓名/手机号" enter-button="搜索" size="large" :loading="searchIng" @search="onSearch" />
			<a-input-search v-show="curInd == 1" v-model="searchInfo2" placeholder="请输入姓名/手机号" enter-button="搜索" size="large" :loading="searchIng2" @search="onSearch2" />
		</div>
		<div class="control-box flex space-between mb20">
			<div class="flex">
				<a-button @click="changeTab(0)" :type="curInd == 0 ? 'primary' : ''">注册用户</a-button>
				<a-button @click="changeTab(1)" :type="curInd == 1 ? 'primary' : ''">导入用户</a-button>
			</div>
			<div class="flex" v-show="curInd == 0">
				<a-button type="link"><a href="https://jcapi.mtree.cn/static/excel/customerImport.xls?ver=1.01" download="用户导入模板">下载模板</a></a-button>
				<a-upload
					:multiple="false"
					:beforeUpload="beforeUpload"
					action="https://jcapi.mtree.cn/api/admin/cusImport"
					:showUploadList="false"
					:data="{
						token: token
					}"
					@change="handleChange"
				>
					<a-button :loading="uping" type="primary" style="margin-right: 10px;">批量导入</a-button>
				</a-upload>
				<a-button type="primary" @click="pldc">批量导出</a-button>
			</div>
		</div>
		<div class="table-box">
			<a-table
				v-show="curInd == 0"
				:rowKey="
					(item, index) => {
						return index;
					}
				"
				:loading="dataLoading"
				:columns="columns"
				:data-source="list"
				:pagination="{
					hideOnSinglePage: true,
					current: page.current,
					total: page.total
				}"
				@change="listChange"
				:bordered="true"
			>
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-button size="small" type="primary" @click="showEditModal(item)">编辑</a-button>
						<a-popconfirm title="确认删除该用户" ok-text="是" cancel-text="否" @confirm="deleteUser(item)">
							<a-button size="small" type="danger">删除</a-button>
						</a-popconfirm>
					</div>
				</template>
			</a-table>

			<a-table
				v-show="curInd == 1"
				:rowKey="
					(item, index) => {
						return index;
					}
				"
				:loading="dataLoading"
				:columns="columns"
				:data-source="list2"
				:pagination="{
					hideOnSinglePage: true,
					current: page2.current,
					total: page2.total
				}"
				@change="listChange2"
				:bordered="true"
			>
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-button size="small" type="primary" @click="showEditModal(item)">编辑</a-button>
						<a-popconfirm title="确认删除该用户" ok-text="是" cancel-text="否" @confirm="deleteUser(item)">
							<a-button size="small" type="danger">删除</a-button>
						</a-popconfirm>
					</div>
				</template>
			</a-table>
		</div>
		<a-modal v-model="editShow" title="修改用户" :cancelText="'取消'" :okText="'提交'" @ok="submitEdit">
			<a-form-model>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="姓名:"><a-input v-model="editInfo.name" /></a-form-model-item>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="手机号:"><a-input v-model="editInfo.phone" /></a-form-model-item>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="身份证号:"><a-input v-model="editInfo.id_number" /></a-form-model-item>

				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="是否在职:">
					<a-switch :defaultChecked="editInfo.is_work == 1" @change="zzChange" />
				</a-form-model-item>
				<a-form-model-item v-show="editInfo.is_work == 1" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="单位名称:">
					<a-input v-model="editInfo.company_name" />
				</a-form-model-item>
				<a-form-model-item v-show="editInfo.is_work == 1" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="单位职务:">
					<a-input v-model="editInfo.job_name" />
				</a-form-model-item>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="单位性质:">
					<a-radio-group :options="dwxz" :v-model="editInfo.company_type" :default-value="editInfo.company_type" />
				</a-form-model-item>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="社保:">
					<a-switch :defaultChecked="editInfo.social_security == 1" @change="social_security_change" />
				</a-form-model-item>
				<a-form-model-item v-if="editInfo.social_security == 1" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="社保状态:">
					<a-radio-group :options="sbzt" v-model="editInfo.social_security_type" :default-value="editInfo.social_security_type" />
				</a-form-model-item>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="常住地:">
					<!-- <a-switch :defaultChecked="editInfo.social_security == 1" @change="zzChange"/> -->
					<a-cascader
						:field-names="{ label: 'name', value: 'id', children: 'districts' }"
						:load-data="loadSsq"
						change-on-select
						:options="ssqData"
						placeholder="请选择地区"
						@change="ssqChange"
					/>
				</a-form-model-item>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="详细地址:"><a-input v-model="editInfo.usually_address" /></a-form-model-item>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="户籍地:">
					<!-- <a-switch :defaultChecked="editInfo.social_security == 1" @change="zzChange"/> -->
					<a-cascader
						:field-names="{
							label: 'name',
							value: 'name',
							children: 'districts'
						}"
						:load-data="loadSsq2"
						change-on-select
						:options="ssqData2"
						placeholder="请选择地区"
						@change="ssqChange2"
					/>
				</a-form-model-item>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="详细地址:"><a-input v-model="editInfo.domicile_address" /></a-form-model-item>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="政治面貌:">
					<a-radio-group :options="zzmm" v-model="editInfo.political_status" :default-value="editInfo.political_status" />
				</a-form-model-item>
				<a-form-model-item v-if="editInfo.political_status == 1" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="所在支部:">
					<a-input v-model="editInfo.branch" />
				</a-form-model-item>
				<a-form-model-item v-if="editInfo.political_status == 1" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="支部性质:">
					<a-radio-group :options="zbxz" v-model="editInfo.branch_nature" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<input type="file" id="pldrEle" style="position: fixed;left: 0;top: 0;opacity: 0;pointer-events: none;" />
	</div>
</template>
<script>
import { pro_api } from '@/siteInfo.js';
import { APP_ID, APP_SECRET } from '@/siteInfo.js';
let columns = [
	{
		title: 'ID',
		key: 'id',
		dataIndex: 'id'
	},
	{
		title: '姓名',
		key: 'name',
		dataIndex: 'name'
	},
	{
		title: '手机号',
		key: 'phone',
		dataIndex: 'phone'
	},
	{
		title: '身份证号',
		key: 'id_number',
		dataIndex: 'id_number'
	},
	{
		title: '是否在职',
		customRender: (text, record, index) => `${record.is_work == 1 ? '在职' : '不在职'}`
	},
	{
		title: '单位职务',
		key: 'job_name',
		dataIndex: 'job_name'
	},
	{
		title: '单位性质',
		customRender: (text, record, index) => `${record.company_type == 1 ? '自己创业' : record.company_type == 2 ? '合伙协办' : '企业雇佣'}`
	},
	{
		title: '社保',
		customRender: (text, record, index) => `${record.social_security == 1 ? (record.social_security_type == 1 ? '个人缴费' : '单位缴费') : '否'}`
	},
	{
		title: '常住地',
		customRender: (text, record, index) =>
			`${record.usually_pro + record.usually_city + record.usually_county + record.usually_street + record.usually_community + record.usually_address}`
	},
	{
		title: '户籍地',
		customRender: (text, record, index) =>
			`${record.domicile_pro + record.domicile_city + record.domicile_county + record.domicile_street + record.domicile_community + record.domicile_address}`
	},
	{
		title: '政治面貌',
		customRender: (text, record, index) => `${record.political_status == '1' ? '中共党员' : record.political_status == 2 ? '群众' : '其他'}`
	},
	{
		title: '所在支部',
		key: 'branch',
		dataIndex: 'branch'
	},
	{
		title: '支部性质',
		customRender: (text, record, index) => `${record.branch_nature == '0' ? '--' : record.branch_nature == 1 ? '社区' : record.branch_nature == 2 ? '工作单位' : '未转岗'}`
	},
	{
		title: '状态',
		customRender: (text, record, index) => `${record.is_face == '1' ? '识别' : '未识别'}`
	},
	{
		title: '操作',
		dataIndex: 'operation',
		width: '128px',
		scopedSlots: { customRender: 'operation' }
	}
];
let columns2 = [
	{
		title: 'ID',
		key: 'id',
		dataIndex: 'id'
	},
	{
		title: '姓名',
		key: 'name',
		dataIndex: 'name'
	},
	{
		title: '手机号',
		key: 'phone',
		dataIndex: 'phone'
	},
	{
		title: '身份证号',
		key: 'id_number',
		dataIndex: 'id_number'
	},
	{
		title: '是否在职',
		customRender: (text, record, index) => `${record.is_work == 1 ? '在职' : '不在职'}`
	},
	{
		title: '单位职务',
		key: 'job_name',
		dataIndex: 'job_name'
	},
	{
		title: '单位性质',
		customRender: (text, record, index) => `${record.company_type == 1 ? '自己创业' : record.company_type == 2 ? '合伙协办' : '企业雇佣'}`
	},
	{
		title: '社保',
		customRender: (text, record, index) => `${record.social_security == 1 ? (record.social_security_type == 1 ? '个人缴费' : '单位缴费') : '否'}`
	},
	{
		title: '常住地',
		customRender: (text, record, index) =>
			`${record.usually_pro + record.usually_city + record.usually_county + record.usually_street + record.usually_community + record.usually_address}`
	},
	{
		title: '户籍地',
		customRender: (text, record, index) =>
			`${record.domicile_pro + record.domicile_city + record.domicile_county + record.domicile_street + record.domicile_community + record.domicile_address}`
	},
	{
		title: '政治面貌',
		customRender: (text, record, index) => `${record.political_status == '1' ? '中共党员' : record.political_status == 2 ? '群众' : '其他'}`
	},
	{
		title: '所在支部',
		key: 'branch',
		dataIndex: 'branch'
	},
	{
		title: '支部性质',
		customRender: (text, record, index) => `${record.branch_nature == '0' ? '--' : record.branch_nature == 1 ? '社区' : record.branch_nature == 2 ? '工作单位' : '未转岗'}`
	},
	{
		title: '操作',
		dataIndex: 'operation',
		width: '128px',
		scopedSlots: { customRender: 'operation' }
	}
];
let dwxz = [
	{
		label: '自己创业',
		value: 1
	},
	{
		label: '合伙协办',
		value: 2
	},
	{
		label: '企业雇佣',
		value: 3
	}
];

let zzmm = [
	{
		label: '中共党员',
		value: 1
	},
	{
		label: '群众',
		value: 2
	},
	{
		label: '其他',
		value: 3
	}
];
let zbxz = [
	{
		label: '--',
		value: 0
	},
	{
		label: '社区',
		value: 1
	},
	{
		label: '工作单位',
		value: 2
	},
	{
		label: '未转岗',
		value: 3
	}
];
let sbzt = [
	{
		label: '个人缴费',
		value: 1
	},
	{
		label: '单位缴费',
		value: 2
	}
];
var pldrEle = null;
export default {
	data() {
		return {
			APP_ID: '',
			APP_SECRET: '',
			dwxz: dwxz,
			zzmm: zzmm,
			zbxz: zbxz,
			sbzt: sbzt,
			editShow: false,
			columns: columns,
			columns2: columns2,
			ssqData: [],
			ssqData2: [],
			searchInfo: '',
			searchInfo2: '',
			dataLoading: false,
			dataLoading2: false,
			list: [],
			list2: [],
			searchIng: false,
			searchIng2: false,
			page: {
				current: 1,
				total: 0
			},
			page2: {
				current: 1,
				total: 0
			},
			editInfo: {
				name: '',
				phone: '',
				id_number: '',
				is_work: 1,
				company_name: '',
				job_name: '',
				company_type: '',
				social_security: '',
				usually_pro_code: '',
				usually_city_code: '',
				usually_county_code: '',
				usually_street_code: '',
				usually_community_code: '',
				usually_pro: '',
				usually_city: '',
				usually_county: '',
				usually_street: '',
				usually_community: '',
				usually_address: '',
				domicile_pro_code: '',
				domicile_city_code: '',
				domicile_county_code: '',
				domicile_street_code: '',
				domicile_community_code: '',
				domicile_pro: '',
				domicile_city: '',
				domicile_county: '',
				domicile_street: '',
				domicile_community: '',
				domicile_address: '',
				political_status: '',
				branch: '',
				zbxz: ''
			},
			pldrEle: null,
			file: null,
			curInd: 0,
			uping: false
		};
	},
	computed: {
		token() {
			return this.$store.state.user.token;
		}
	},
	created() {
		let p = { a: 1, d: 2, c: 2 };
		let { d, a, c } = p;
	},
	mounted() {
		this.APP_SECRET = APP_SECRET;
		this.APP_ID = APP_ID;
		this.getUserList();
		this.getUserList2();
		this.getInitMap();
		let t = this;
		t.pldrEle = document.getElementById('pldrEle');
		t.pldrEle.addEventListener('change', function(e) {
			console.log(e.target.files[0]);
			t.file = e.target.files[0];
			console.log(e);
			let data = { filesadasd: e.target.files[0] };
			console.log(data);
			const formData = new FormData();
			formData.append('file', e.target.files[0]);
			t.$nextTick(() => {
				t.$post({
					port: 'a',
					url: 'cusImport',
					data: formData
				})
					.then(res => {
						let { code, data, msg } = res;
						if (code == 0) {
							t.$message.success(msg, 1.5);
							t.getUserList();
						} else {
							t.$message.error(msg, 1.5);
						}
					})
					.catch(err => {});

				// t.$get({
				// 	port: 'a',
				// 	url: 'sendtongzhi',
				// 	data: {}
				// }).then(res => {
				// 	let { code, data, msg } = res
				// 	if (code == 0) {
				// 		t.$message.success(msg, 1.5)
				// 		t.$router.go(-1)
				// 	} else {
				// 		t.$message.error(msg, 1.5)
				// 	}
				// }).catch(err => {

				// })
			});
		});
	},
	methods: {
		changeTab(ind) {
			this.curInd = ind;
		},
		beforeUpload(file) {
			this.file = file;
			this.uping = true;
			console.log('before', file);
		},
		handleChange(e) {
			console.log('改变。。。');
			if (e.file.status == 'done') {
				console.log(e.file.response);
				this.uping = false
				let { msg, data, code } = e.file.response;
				if (code == 0) {
					this.$message.success(msg, 1.5);
				} else {
					this.$message.error(msg, 1.5);
				}
				console.log(data);
			}
		},
		getInitMap() {
			let t = this;
			t.$get({
				port: 'a',
				url: 'city',
				data: { type: 1 }
			})
			.then(res => {
				let { code, data, msg } = res;
				if (code == 0) {
					console.log('ditu', data);
					for (let i = 0; i < data.length; i++) {
						data[i].isLeaf = false;
					}
					t.ssqData = data;
					t.ssqData2 = data;
				} else {
					t.$message.error(msg, 1.5);
				}
			})
			.catch(err => {});
		},
		onSearch() {
			this.page.current = 1;
			this.searchIng = true;
			this.getUserList();
		},
		onSearch2() {
			this.page2.current = 1;
			this.searchIng2 = true;
			this.getUserList2();
		},
		getUserList() {
			let t = this;
			t.dataLoading = true;
			t.$get({
				port: 'a',
				url: 'customerlist',
				data: {
					searchInfo: t.searchInfo,
					page: t.page.current
				}
			})
				.then(res => {
					let { code, data, msg } = res;
					t.dataLoading = false;
					t.searchIng = false;
					if (code == 0) {
						t.list = data.list;
						t.page.total = data.total;
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					t.dataLoading = false;
					t.searchIng = false;
				});
		},
		getUserList2() {
			let t = this;
			t.dataLoading2 = true;
			t.$get({
				port: 'a',
				url: 'customerImportList',
				data: {
					searchInfo: t.searchInfo2,
					page: t.page2.current
				}
			})
				.then(res => {
					let { code, data, msg } = res;
					t.dataLoading2 = false;
					t.searchIng2 = false;
					if (code == 0) {
						t.list2 = data.list;
						t.page2.total = data.total;
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					t.dataLoading2 = false;
					t.searchIng2 = false;
				});
		},
		loadSsq(selectedOptions) {
			let t = this;
			const targetOption = selectedOptions[selectedOptions.length - 1];
			console.log(targetOption);
			targetOption.loading = true;
			// load options lazily
			t.$get({
				port: 'a',
				url: 'city',
				data: {
					type: parseInt(targetOption.level) + 1,
					code: targetOption.id
				}
			})
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						console.log(data);
						targetOption.districts = data;
						this.ssqData = [...this.ssqData];
						targetOption.loading = false;
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {});
		},
		loadSsq2(selectedOptions) {
			let t = this;
			const targetOption = selectedOptions[selectedOptions.length - 1];
			console.log(targetOption);
			targetOption.loading = true;
			// load options lazily
			t.$get({
				port: 'a',
				url: 'city',
				data: {
					type: parseInt(targetOption.level) + 1,
					code: targetOption.id
				}
			})
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						console.log(data);
						targetOption.districts = data;
						this.ssqData2 = [...this.ssqData2];
						targetOption.loading = false;
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {});
		},
		ssqChange(e, opt) {
			console.log(e, opt);
			this.editInfo.usually_pro_code = e[0] || '';
			this.editInfo.usually_city_code = e[1] || '';
			this.editInfo.usually_county_code = e[2] || '';
			this.editInfo.usually_street_code = e[3] || '';
			this.editInfo.usually_community_code = e[4] || '';
			for(let i = 0;i < opt.length; i++) {
				if(i == 0) {
					this.editInfo.usually_pro = opt[0].name
				}else if(i == 1) {
					this.editInfo.usually_city = opt[1].name
				}else if(i == 2) {
					this.editInfo.usually_county = opt[2].name
				}else if(i == 3) {
					this.editInfo.usually_street = opt[3].name
				}else if(i == 4) {
					this.editInfo.usually_community = opt[4].name
				}
			}
		},
		ssqChange2(e, opt) {
			this.editInfo.domicile_pro_code = e[0] || '';
			this.editInfo.domicile_city_code = e[1] || '';
			this.editInfo.domicile_county_code = e[2] || '';
			this.editInfo.domicile_street_code = e[3] || '';
			this.editInfo.domicile_community_code = e[4] || '';

			this.editInfo.domicile_pro = opt[0].name || '';
			this.editInfo.domicile_city = opt[1].name || '';
			this.editInfo.domicile_county = opt[2].name || '';
			this.editInfo.domicile_street = opt[3].name || '';
			this.editInfo.domicile_community = opt[4].name || '';
			
			for(let i = 0;i < opt.length; i++) {
				if(i == 0) {
					this.editInfo.domicile_pro = opt[0].name
				}else if(i == 1) {
					this.editInfo.domicile_city = opt[1].name
				}else if(i == 2) {
					this.editInfo.domicile_county = opt[2].name
				}else if(i == 3) {
					this.editInfo.domicile_street = opt[3].name
				}else if(i == 4) {
					this.editInfo.domicile_community = opt[4].name
				}
			}
		},
		zzChange(e) {
			console.log(e);
			if (e) {
				console.log(e);
				this.editInfo.is_work = 1;
			} else {
				this.editInfo.is_work = 2;

				this.editInfo.company_type = '';
				this.editInfo.company_name = '';
				this.editInfo.job_name = '';
			}
			console.log(this.editInfo.is_work);
		},
		social_security_change(e) {
			console.log('she bao gaibian', e);
			if (e) {
				this.editInfo.social_security = 1;
			} else {
				this.editInfo.social_security = 2;
			}
		},
		company_type_change(e) {
			this.editInfo.company_type = e.target.value;
		},
		political_status_change(e) {
			// this.editInfo.political_status = e.target.value;
			if (e.target.value != 1) {
				this.editInfo.branch = '';
				this.editInfo.branch_nature = '';
			}
		},
		branch_nature_change(e) {
			this.editInfo.branch_nature = e.target.value;
		},
		showEditModal(item) {
			this.editShow = true;
			this.editInfo.customer_id = item.customer_id;
			this.editInfo.name = item.name;
			this.editInfo.phone = item.phone;
			this.editInfo.id_number = item.id_number;
			this.editInfo.is_work = item.is_work;
			this.editInfo.job_name = item.job_name;
			this.editInfo.company_type = item.company_type;
			this.editInfo.company_name = item.company_name;
			console.log(typeof item.company_type);
			this.editInfo.social_security = item.social_security;

			this.editInfo.usually_pro = item.usually_pro;
			this.editInfo.usually_city = item.usually_city;
			this.editInfo.usually_county = item.usually_county;
			this.editInfo.usually_street = item.usually_street;
			this.editInfo.usually_community = item.usually_community;
			this.editInfo.usually_address = item.usually_address;

			this.editInfo.domicile_pro = item.domicile_pro;
			this.editInfo.domicile_city = item.domicile_city;
			this.editInfo.domicile_county = item.domicile_county;
			this.editInfo.domicile_street = item.domicile_street;
			this.editInfo.domicile_community = item.domicile_community;
			this.editInfo.domicile_address = item.domicile_address;

			this.editInfo.political_status = item.political_status;
			this.editInfo.branch_nature = item.branch_nature;
		},
		listChange(e) {
			this.page.current = e.current;
			this.getUserList();
		},
		listChange2(e) {
			this.page2.current = e.current;
			this.getUserList2();
		},
		submitEdit() {
			let t = this;
			let url = t.curInd == 0 ? 'setCusInfo' : 'setCusImportInfo';
			t.$post({
				port: 'a',
				url: url,
				data: t.editInfo
			})
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						t.$message.success(msg, 1.5);
						t.editShow = false;
						t.editInfo = {};
						if (t.curInd == 0) {
							t.getUserList();
						} else {
							t.getUserList2();
						}
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {});
		},
		deleteUser(item) {
			console.log(item);
			let customer_id = item.customer_id;
			let t = this;
			let url = t.curInd == 0 ? 'deleteCus' : 'deleteImportCus';
			t.$get({
				port: 'a',
				url: url,
				data: { customer_id: customer_id }
			})
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						t.$message.success(msg, 1.5);
						if (t.curInd == 0) {
							t.getUserList();
						} else {
							t.getUserList2();
						}
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {});
		},
		pldr() {
			let t = this;
			let pldrEle = document.getElementById('pldrEle');
			pldrEle.click();
		},
		pldc() {
			let t = this;
			window.open(pro_api.a + 'cusExport?searchInfo=' + t.searchInfo);
			// window.open(pro_api.a +'typeCusExport?type='+t.userParams.type+'&wenjuan_id='+t.userParams.wenjuan_id)
		}
	}
};
</script>

<style lang="less">
.control-box {
	.ant-btn {
		margin-right: 10px;
		&:last-child {
			margin-right: 0;
		}
	}
}
</style>
